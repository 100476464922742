a.usa-media-block { text-decoration: none; }

.policy-list {
  & li + li::before { content: " | "; }
}

.usa-social-link--github {
  background-image: url('../../images/social-icons/png/github32.png');
  background-repeat: no-repeat;
}

.usa-social-link--linkedin {
  background-image: url('../../images/social-icons/png/linkedin32.png');
  background-repeat: no-repeat;
}
