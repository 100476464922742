/*  deprecated.scss
    ---
    Occasionally the design system will deprecate
    old variables or functionality. If we replace
    the old functionality with something new, this is a
    place to connect the old functionality to the
    new functionality, in the service of better
    continuity and backwards compatibility within a
    major release cycle.

    Note the USWDS version where we deprecated the
    old functionality in a comment.

    Be sure to update notifications.scss.

    This file should started fresh at each
    major version.
*/

// Deprecated in 2.2.0
$theme-navigation-width: $theme-header-min-width;
$theme-megamenu-logo-text-width: $theme-header-logo-text-width;

// Deprecated in 2.0.2
$theme-title-font-size: $theme-display-font-size;

@mixin title {
  @include display;
}

@mixin typeset-title {
  @include typeset-display;
}
