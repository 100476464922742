.usa-header {
  .usa-nav {
    @if variable-exists(top-nav-bg) {
      background-color: color($top-nav-bg);
    }
  }

  .usa-nav__link {
    &:hover {
      @if variable-exists(top-nav-link-hover-bg) {
        background-color: color($top-nav-link-hover-bg);
      }
    }
  }

  .usa-nav__link {
    span {
      @if variable-exists(top-nav-link) {
        color: color($top-nav-link);
      }
    }

    &.usa-current {
      span {
        @if variable-exists(top-nav-link-hover) {
          color: color($top-nav-link-hover);
        }
      }
    }

    &:hover {
      @if variable-exists(top-nav-link-hover) {
        color: color($top-nav-link-hover);
      }

      span {
        @if variable-exists(top-nav-link-current) {
          border-bottom-color: color($top-nav-link-current);
        }
        @if variable-exists(top-nav-link-hover) {
          color: color($top-nav-link-hover);
        }
      }
    }

    .usa-current,
    button[aria-expanded="true"] {
      span {
        @if variable-exists(top-nav-link-current) {
          border-color: color($top-nav-link-current);
        }
        @if variable-exists(top-nav-link-current) {
          color: color($top-nav-link-current);
        }
      }
    }
  }

  @include at-media($theme-header-min-width) {
    button[aria-expanded="true"],
    button[aria-expanded="true"]:hover,
    .usa-nav__submenu {
      @if variable-exists(top-nav-dropdown-bg) {
        background-color: color($top-nav-dropdown-bg);
      }
      span {
        @if variable-exists(top-nav-dropdown-link) {
          color: color($top-nav-dropdown-link);
        }
      }
    }
  }

  .usa-nav__submenu {
    a:hover {
      @if variable-exists(top-nav-dropdown-link-hover-bg) {
        background-color: color($top-nav-dropdown-link-hover-bg);
      }
      @if variable-exists(top-nav-dropdown-link) {
        color: color($top-nav-dropdown-link);
      }
    }
  }
}
